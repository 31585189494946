import { useState } from 'react';
import * as Yup from 'yup';
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Box, Typography, TextField, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import styled from '@emotion/styled';
import axiosInstance from 'src/utils/axios';
import { useUpdateSteps } from 'src/contexts/FormUpdateContext';
import { CrcModal } from './steps/CrcModal';
import InputMask from 'react-input-mask';

// ----------------------------------------------------------------------

type FormValuesProps = {
  UserCpf: string;
  birthDate: string;
  afterSubmit?: string;
};

const BoxButtonUpdate = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  marginTop: '35px',
}));

export const regexCamp = (value: any) => /^(?=.*[_])./gm.test(value);

// ----------------------------------------------------------------------

export default function UpdateInitialForm() {
  const { setStepOne, setStepTwo, handleCurrentUser } = useUpdateSteps();

  const [open, setOpen] = useState(false);
  const [dataForm, setDataForm] = useState<any>();
  const [textError, settextError] = useState<string>('');
  const theme = useTheme();

  function handleOpen(data: { document: string; birthdate: string }, text: string) {
    settextError(text);
    setDataForm(data);
    setOpen(true);
  }

  function handleClose() {
    setDataForm(undefined);
    setOpen(false);
  }

  const maxDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];

  const LoginSchema = Yup.object().shape({
    UserCpf: Yup.string()
      .min(14, 'Digite o CPF corretamente!')
      .required('Digite o CPF do titular')
      .test(
        'Digite o CPF corretamente!',
        'Digite o CPF corretamente!',
        (value: any) => !regexCamp(value)
      ),
    birthDate: Yup.date()
      .typeError('Digite uma data válida')
      .required('Digite a data de nascimento!'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      UserCpf: '',
      birthDate: '',
    }
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  function formatDate(date: string) {
    const newDate = new Date(date);
    const dateFormatted = newDate.toISOString();
    const datePart = dateFormatted.split('T');
    return `${datePart[0]}`;
  }

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const cpf = data.UserCpf.replace(/[^0-9]/g, '');
      const response = await axiosInstance.post('/users/verify-user-exist', {
        cpf: cpf,
        birthdate: formatDate(data.birthDate),
      });

      const user = response.data;
      handleCurrentUser(user);
      setStepOne(false);
      setStepTwo(true);
    } catch (error) {
      console.log(error);
      const cpf = data.UserCpf.replace(/[^0-9]/g, '');

      handleOpen({ document: cpf, birthdate: data.birthDate }, error.message);
    }
  };

  // ----------------------------------------------------------------------

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            CPF
          </Typography>

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputMask mask="999.999.999-99" value={value} onChange={onChange}>
                <TextField
                  name="UserCpf"
                  type="text"
                  fullWidth
                  error={Boolean(errors.UserCpf)}
                  helperText={error?.message}
                />
              </InputMask>
            )}
            name="UserCpf"
            control={control}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" color={theme.palette.primary.main}>
            Data de Nascimento
          </Typography>
          <RHFTextField name="birthDate" type="date" inputProps={{ max: maxDate }} />
        </Box>
      </Stack>
      <BoxButtonUpdate>
        <LoadingButton
          fullWidth
          size="large"
          variant="outlined"
          color="secondary"
          type="submit"
          href="/auth/login"
          style={{ marginRight: 10 }}
        >
          <MdOutlineKeyboardArrowLeft />
          Voltar
        </LoadingButton>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Avançar
          <MdOutlineKeyboardArrowRight />
        </LoadingButton>
      </BoxButtonUpdate>
      <CrcModal open={open} handleClose={handleClose} dataForm={dataForm} text={textError} />
    </FormProvider>
  );
}
