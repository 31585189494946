import { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import Avatar, { Props as AvatarProps } from './Avatar';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

interface DependentAvatarProps extends AvatarProps {
    userId: number;
    hasImage: boolean;
}

export default function DependentAvatar({ userId, hasImage, ...other }: DependentAvatarProps) {

    const [imageUrl, setImageUrl] = useState('');
    const getPresignedURL = async (userId: number) => {
        const responseURL = await axiosInstance.get(`/users/presigned-photos/${userId}`);
        setImageUrl(responseURL.data);
    };

    useEffect(() => {
        if (hasImage) {
            getPresignedURL(userId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Avatar
            src={imageUrl}
            color={imageUrl ? 'default' : 'primary'}
            {...other}
            sx={{ width: 32, height: 32 }}
        >
            <Iconify icon={'fa-regular:user'} sx={{ width: 16, height: 16 }} />
        </Avatar>
    );
}

